import React, { useState } from "react";
import themestyle from "../theme";
import {
  Grid,
  useMediaQuery,
  useTheme,
  Typography,
  Link,
  Box,
} from "@mui/material";
import ContactModal from "./ContactModal";
import { PheonixProps } from "../interfaces/HomeProps";
import data from "../data/data.json";
import EmailIcon from "../assets/mailicon.png";
import PublicIcon from "../assets/webicon.png";
import PhoneIcon from "../assets/phoneicon.png";
import { Cover } from "./UI/Cover";
import ContactImage from "../assets/bro.svg";
import { Button } from "./UI/RequestButton";

const Contact: React.FC<PheonixProps> = ({ sections }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLaptopSmall = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [modalOpen, setModalOpen] = useState(false);
  const isLaptoplg = useMediaQuery('(min-width: 1024px) and (max-height: 345px)');

  const handleActionClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
<>
    <div ref={sections.contactRef}>
      <> 
      {!isTablet && 
      <Grid
        container
        style={{
          textAlign: "left",
          justifyContent: "center",
          marginTop: themestyle.spacing.medium,
          marginBottom: themestyle.spacing.medium,
          padding: isSmallScreen ? "20px" : "10px",
          gap: isSmallScreen ? "0px" : "30px",
          minHeight: "400px",
        }}
        sx={{
          width: "100% !important",
          marginLeft: "0px !important",
        }}
        spacing={2}
      >
        <Grid
          container
          item
          xs={12}
          md={6}
          lg={7}
          spacing={0}
          ref={sections.contactRef}
          style={{
            borderRadius: "10px",
            border: "2px solid transparent",
            borderImage: `linear-gradient(90deg, ${themestyle.colors.bgprimary} 0%, ${themestyle.colors.bgsecondary} 100%)`,
            borderImageSlice: 1,
            height: isSmallScreen ? "auto" : isTablet ? "360px" : "360px",
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            style={{
              width: isSmallScreen ? "100%" : "181px",
              height: isSmallScreen ? "auto" : "auto",
              padding: isSmallScreen ? "0px" : "0px",
            }}
          >
            <Typography
              variant="h4"
              style={{
                fontSize: isSmallScreen
                  ? themestyle.fontSizes.medium
                  : themestyle.fontSizes.large,
                fontWeight: 600,
                fontFamily: "Lexend",
                color: "#101828",
                marginBottom: themestyle.spacing.small,
              }}
            >
              {data.contact.Contact}
            </Typography>
            <Typography
              variant="body2"
              style={{
                fontFamily: "Lexend",
                marginBottom: isSmallScreen ? "20px" : "40px",
                fontSize: "18px",
              }}
            >
              {data.contact.Build}
            </Typography>

            <Typography
              variant="body1"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: isSmallScreen ? "14px" : "18px",
                fontWeight: 600,
                fontFamily: "Lexend",
                marginBottom: themestyle.spacing.small,
                marginTop: "20px",
              }}
            >
              <Box
                component="img"
                src={PublicIcon}
                alt="Website Icon"
                style={{ marginRight: "8px", width: "16px", height: "16px" }}
              />
              <Link
                href={data.contact.WebsiteLink || "#"}
                target="_blank"
                rel="noopener"
                sx={{
                  color: "inherit",
                  textDecoration: "underline",
                  "&:hover": {
                    color: "#208BEE",
                    textDecoration: "underline",
                  },
                }}
              >
                {`${data.contact.Website} `}
              </Link>
            </Typography>

            <Typography
              variant="body1"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: isSmallScreen ? "14px" : "18px",
                fontWeight: 600,
                fontFamily: "Lexend",
                marginTop: "20px",
                cursor: "pointer",
              }}
            >
              <img
                src={EmailIcon}
                alt="Email Icon"
                style={{ marginRight: "8px", width: "16px", height: "12px" }}
              />
              <Link
                href="mailto:support@pheonixsolutions.com"
                sx={{
                  color: "inherit",
                  textDecoration: "underline",
                  "&:hover": {
                    color: "#208BEE",
                    textDecoration: "underline",
                  },
                }}
              >
                {data.contact.Mail}
              </Link>
            </Typography>
          </Grid>

          {isSmallScreen && (
            <>
              <Grid
                container
                spacing={2}
                alignItems="flex-start"
                style={{
                  fontSize: "14px",
                  fontFamily: "Lexend",
                  padding: "10px",
                  marginLeft: "-25px",
                }}
              >
                <Grid
                  item
                  xs={12}
                  md="auto"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >

                    <Box
                      component="img"
                      src={PhoneIcon}
                      alt="Phone Icon"
                      style={{
                        marginRight: "8px",
                        width: "16px",
                        height: "16px",
                      }}
                    />

                  <Box>
                    <Typography style={{ fontWeight: 700, marginRight: "5px" }}>
                      {data.contact.India}
                      <span style={{ fontWeight: "500" }}>
                        {data.contact.IndiaContact}&nbsp;
                      </span>
                    </Typography>
                    <Typography style={{ fontWeight: 700, marginRight: "5px" }}>
                      {data.contact.Ireland}
                      <span style={{ fontWeight: "500" }}>
                        {data.contact.IrelandContact}&nbsp;
                      </span>
                    </Typography>
                    <Typography style={{ fontWeight: 700, marginRight: "5px" }}>
                      {data.contact.US}
                      <span style={{ fontWeight: "500" }}>
                        {data.contact.USContact}
                      </span>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}

          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: "flex",
              justifyContent: isLaptopSmall
                ? "flex-end"
                : isTablet
                ? "center"
                : "center",
              alignItems: "left",
              padding: isSmallScreen ? "20px" : "10px",
            }}
          >
            <Box
              component="img"
              src={ContactImage}
              alt="Contact Us"
              style={{
                maxWidth: isSmallScreen ? "100%" : "300px",
                height: "155px",
                borderRadius: "10px",
                marginTop: !isSmallScreen
                  ? "20px"
                  : isTablet
                  ? "-200px"
                  : "0px",
                marginLeft: isTablet ? "300px" : "0px",
              }}
            />
          </Grid>

          {!isSmallScreen && (
            <Grid
              container
              alignItems="center"
              style={{
                fontSize: "18px",
                fontFamily: "Lexend",
                marginBottom: isLaptopSmall ? "10px" : "120px",
              }}
            >
              <Grid
                item
                xs={12}
                md="auto"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginLeft:isLaptopSmall ? "0px" : isLaptoplg ? "0px" : "0px",
                  marginTop: "20px",
                }}
              >
                <Box
                  component="img"
                  src={PhoneIcon}
                  alt="Phone Icon"
                  style={{ marginRight: "8px", width: "16px", height: "16px" }}
                />
                <Box
                  component="span"
                  style={{ fontWeight: 700, marginRight: "5px" }}
                >
                  {data.contact.India}
                </Box>
                <Box style={{ fontWeight: 400 }}>
                  {data.contact.IndiaContact}&nbsp;
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md="auto"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginTop: "20px",
                }}
              >
                <Box
                  component="span"
                  style={{ fontWeight: 700, marginRight: "5px" }}
                >
                  | {data.contact.Ireland}
                </Box>
                <Box style={{ fontWeight: 400 }}>
                  {data.contact.IrelandContact}&nbsp;
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md="auto"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginTop: "20px",
                }}
              >
                <Box
                  component="span"
                  style={{ fontWeight: 700, marginRight: "5px" }}
                >
                  | {data.contact.US}
                </Box>
                <Box style={{ fontWeight: 400 }}>{data.contact.USContact}</Box>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid
          item
          lg={4}
          xs={12}
          md={4}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "10px",
            marginTop: isSmallScreen ? "20px" : "0px",
            backgroundColor: "#f7f7f7",
            textAlign: "left",
            color: "#101828",
            width: isSmallScreen ? "100%" : "478px",
            height: isSmallScreen ? "auto" : "362px",
            border: "2px solid transparent",
            borderImage: `linear-gradient(90deg, ${themestyle.colors.bgprimary} 0%, ${themestyle.colors.bgsecondary} 100%)`,
            borderImageSlice: 1,
          }}
        >
          <Typography
            variant="h6"
            style={{
              fontFamily: "Lexend",
              fontWeight: 500,
              color: "#101828",
              fontSize: isLaptopSmall
                ? "x-large"
                : isSmallScreen
                ? "24px"
                : "32px",
              marginTop: isLaptopSmall
                ? "20px"
                : isSmallScreen
                ? "20px"
                : "60px",
            }}
          >
            <Cover>
              <span> {data.contact.Showcase}</span>

              <br />
              {data.contact.WhatyouDo}
              <br />
              <span>{data.contact.days}</span>
              <br />
              {data.contact.Build}
            </Cover>
          </Typography>

          <Box
            display="flex"
            justifyContent="flex-end"
            width="100%"
            sx={{ padding: "10px" }}
          >
            <Button
              variant="contained"
              className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800"
              onClick={handleActionClick}
              text="Request"
              style={{
                backgroundImage:
                  "linear-gradient(90deg, #44C597 0%, #0177BF 100%)",
                color: "#fff",
                textTransform: "none",
                fontFamily: "Lexend",
                fontSize: "16px",
                fontWeight: 500,
                borderRadius: "100px",
                marginTop: 0,
                marginBottom: isSmallScreen
                  ? "0px"
                  : isLaptopSmall
                  ? "0px"
                  : "50px",
                width: "199px",
                height: "50px",
              }}
            >
              {data.contact.Request}
            </Button>
          </Box>
          <ContactModal open={modalOpen} onClose={handleCloseModal} />
        </Grid>
      </Grid>
}
</>
<>
      {isTablet && <Grid
        container
        style={{
          textAlign: "left",
          justifyContent: "center",
          marginTop: themestyle.spacing.medium,
          flexWrap:"nowrap",
          padding:"20px 20px 50px 20px",
          gap: "30px",
        }}
        sx={{
          width: "100% !important",
          marginLeft: "0px !important",
        }}
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={6}
          lg={7}
          spacing={0}
          ref={sections.contactRef}
          style={{
            borderRadius: "10px",
            border: "2px solid transparent",
            borderImage: `linear-gradient(90deg, ${themestyle.colors.bgprimary} 0%, ${themestyle.colors.bgsecondary} 100%)`,
            borderImageSlice: 1,
            height:"300px",
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            style={{
              width: isSmallScreen ? "100%" : "181px",
              height: isSmallScreen ? "auto" : "auto",
              padding: isSmallScreen ? "0px" : "0px",
            }}
          >
            <Typography
              variant="h4"
              style={{
                fontSize: isSmallScreen
                  ? themestyle.fontSizes.medium
                  : themestyle.fontSizes.large,
                fontWeight: 600,
                fontFamily: "Lexend",
                color: "#101828",
                marginBottom: themestyle.spacing.small,
              }}
            >
              {data.contact.Contact}
            </Typography>
            <Typography
              variant="body2"
              style={{
                fontFamily: "Lexend",
                marginBottom: isSmallScreen ? "20px" : "40px",
                fontSize: "18px",
              }}
            >
              {data.contact.Build}
            </Typography>

            <Typography
              variant="body1"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: isSmallScreen ? "14px" : "18px",
                fontWeight: 600,
                fontFamily: "Lexend",
                marginBottom: themestyle.spacing.small,
                marginTop: "20px",
              }}
            >
              <Box
                component="img"
                src={PublicIcon}
                alt="Website Icon"
                style={{ marginRight: "8px", width: "16px", height: "16px" }}
              />
              <Link
                href={data.contact.WebsiteLink || "#"}
                target="_blank"
                rel="noopener"
                sx={{
                  color: "inherit",
                  textDecoration: "underline",
                  "&:hover": {
                    color: "#208BEE",
                    textDecoration: "underline",
                  },
                }}
              >
                {`${data.contact.Website} `}
              </Link>
            </Typography>

            <Typography
              variant="body1"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: isSmallScreen ? "14px" : "18px",
                fontWeight: 600,
                fontFamily: "Lexend",
                marginTop: "20px",
                cursor: "pointer",
              }}
            >
              <img
                src={EmailIcon}
                alt="Email Icon"
                style={{ marginRight: "8px", width: "16px", height: "12px" }}
              />
              <Link
                href="mailto:support@pheonixsolutions.com"
                sx={{
                  color: "inherit",
                  textDecoration: "underline",
                  "&:hover": {
                    color: "#208BEE",
                    textDecoration: "underline",
                  },
                }}
              >
                {data.contact.Mail}
              </Link>
            </Typography>
          </Grid>

          {isSmallScreen && (
            <>
              <Grid
                container
                spacing={2}
                alignItems="flex-start"
                style={{
                  fontSize: "14px",
                  fontFamily: "Lexend",
                  padding: "10px",
                  marginLeft: "-25px",
                }}
              >
                <Grid
                  item
                  xs={12}
                  md="auto"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >

                    <Box
                      component="img"
                      src={PhoneIcon}
                      alt="Phone Icon"
                      style={{
                        marginRight: "8px",
                        width: "16px",
                        height: "16px",
                      }}
                    />

                  <Box>
                    <Typography style={{ fontWeight: 700, marginRight: "5px" }}>
                      {data.contact.India}
                      <span style={{ fontWeight: "500" }}>
                        {data.contact.IndiaContact}&nbsp;
                      </span>
                    </Typography>
                    <Typography style={{ fontWeight: 700, marginRight: "5px" }}>
                      {data.contact.Ireland}
                      <span style={{ fontWeight: "500" }}>
                        {data.contact.IrelandContact}&nbsp;
                      </span>
                    </Typography>
                    <Typography style={{ fontWeight: 700, marginRight: "5px" }}>
                      {data.contact.US}
                      <span style={{ fontWeight: "500" }}>
                        {data.contact.USContact}
                      </span>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}

         {!isTablet && <Grid
            item
            xs={12}
            md={6}
            style={{
              display: "flex",
              justifyContent: isLaptopSmall
                ? "flex-end"
                : isTablet
                ? "center"
                : "center",
              alignItems: "left",
              padding: isSmallScreen ? "20px" : "10px",
            }}
          >
            <Box
              component="img"
              src={ContactImage}
              alt="Contact Us"
              style={{
                maxWidth: isSmallScreen ? "100%" : "300px",
                height: "155px",
                borderRadius: "10px",
                marginTop: !isSmallScreen
                  ? "20px"
                  : isTablet
                  ? "-200px"
                  : "0px",
                marginLeft: isTablet ? "300px" : "0px",
              }}
            />
          </Grid> }

          {!isSmallScreen && (
            <Grid
              container
              alignItems="center"
              style={{
                fontSize: "18px",
                fontFamily: "Lexend",
                marginBottom: isLaptopSmall ? "10px" : "120px",
              }}
            >
              <Grid
                item
                xs={12}
                md="auto"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginLeft:isLaptopSmall ? "0px" : isLaptoplg ? "0px" : "0px",
                  marginTop: "20px",
                }}
              >
                <Box
                  component="img"
                  src={PhoneIcon}
                  alt="Phone Icon"
                  style={{ marginRight: "8px", width: "16px", height: "16px" }}
                />
                <Box
                  component="span"
                  style={{ fontWeight: 700, marginRight: "5px" }}
                >
                  {data.contact.India}
                </Box>
                <Box style={{ fontWeight: 400 }}>
                  {data.contact.IndiaContact}&nbsp;
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md="auto"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginTop: "20px",
                }}
              >
                <Box
                  component="span"
                  style={{ fontWeight: 700, marginRight: "5px" }}
                >
                  | {data.contact.Ireland}
                </Box>
                <Box style={{ fontWeight: 400 }}>
                  {data.contact.IrelandContact}&nbsp;
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md="auto"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginTop: "20px",
                }}
              >
                <Box
                  component="span"
                  style={{ fontWeight: 700, marginRight: "5px" }}
                >
                  | {data.contact.US}
                </Box>
                <Box style={{ fontWeight: 400 }}>{data.contact.USContact}</Box>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid
          item
          lg={4}
          xs={12}
          md={6}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "10px",
            backgroundColor: "#f7f7f7",
            textAlign: "left",
            color: "#101828",
            width: isSmallScreen ? "100%" : "478px",
            border: "2px solid transparent",
            borderImage: `linear-gradient(90deg, ${themestyle.colors.bgprimary} 0%, ${themestyle.colors.bgsecondary} 100%)`,
            borderImageSlice: 1,
            height:"300px",
          }}
        >
          <Typography
            variant="h6"
            style={{
              fontFamily: "Lexend",
              fontWeight: 500,
              color: "#101828",
              fontSize: isLaptopSmall
                ? "x-large"
                : isSmallScreen
                ? "24px"
                : "32px",
              marginTop: isLaptopSmall
                ? "20px"
                : isSmallScreen
                ? "20px"
                : "60px",
            }}
          >
            <Cover>
              <span> {data.contact.Showcase}</span>

              <br />
              {data.contact.WhatyouDo}
              <br />
              <span>{data.contact.days}</span>
              <br />
              {data.contact.Build}
            </Cover>
          </Typography>

          <Box
            display="flex"
            justifyContent="flex-end"
            width="100%"
            sx={{ padding: "10px" }}
          >
            <Button
              variant="contained"
              className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800"
              onClick={handleActionClick}
              text="Request"
              style={{
                backgroundImage:
                  "linear-gradient(90deg, #44C597 0%, #0177BF 100%)",
                color: "#fff",
                textTransform: "none",
                fontFamily: "Lexend",
                fontSize: "16px",
                fontWeight: 500,
                borderRadius: "100px",
                marginTop: 0,
                marginBottom: isSmallScreen
                  ? "0px"
                  : isLaptopSmall
                  ? "0px"
                  : "50px",
                width: "199px",
                height: "50px",
              }}
            >
              {data.contact.Request}
            </Button>
          </Box>
          <ContactModal open={modalOpen} onClose={handleCloseModal} />
        </Grid>
      </Grid>}
      </>
    </div>
    </>
   
  );
};

export default Contact;
