import React from "react";
import themestyle from "../theme";
import { PheonixProps } from "../interfaces/HomeProps";
import { Typography, Box, Grid, useTheme, useMediaQuery } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import data from "../data/data.json"; 
import { TEXT_MESSAGES } from "../const";
import { InfiniteMovingCards } from "./UI/CardCarousel";

interface Feedback {
  avatar: string;
  username: string;
  handle: string;
  comment: string;
  rating: number;
}

const Feedback: React.FC<PheonixProps> = ({ sections }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
   return (
    <div
    ref={sections.testimonialRef}
      style={{
        background: `linear-gradient(90deg, ${themestyle.colors.bgprimary} 0%, ${themestyle.colors.bgsecondary} 100%)`,
        overflowX: "hidden",
      }}
    >
      <Grid
        container
        style={{
          textAlign: "left",
          justifyContent: "center",
          marginTop: themestyle.spacing.medium,
          marginBottom: themestyle.spacing.medium,
        }}
        spacing={0}
      >
        <Box sx={{ textAlign: "center", padding: "40px" }}>
          <Typography
            color="#101828"
            component="h2"
            style={{
              fontWeight: 700,
              fontFamily: "Lexend",
              fontSize: "36px",
            }}
          >
            {TEXT_MESSAGES.Feedback}
          </Typography>
          <Typography
          style={{
            color: "#ffffff",
            marginTop: themestyle.spacing.small,
            marginBottom: themestyle.spacing.large,
            fontSize: isMobile ? "20px" : "24px",
            fontWeight: 400,
            fontFamily: "Lexend",
            textAlign: isMobile ? "justify" : "center"
          }}
        >
            {TEXT_MESSAGES.FBDESCRIPTION}
          </Typography>
        </Box>
      </Grid>
  
 
       <InfiniteMovingCards
         items={data.feedbackData}
         direction="right"
         speed="slow"
       />
   
    </div>
  );
};

export default Feedback;
