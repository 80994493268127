import React, { useState } from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import themestyle from "../theme";
import checkBox from "../assets/checkbox.png";
import Handshake from "../assets/handShake.png";
import Read from "../assets/Read.png";
import professional from "../assets/professional.png";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import data from "../data/data.json";
import { Button } from "./UI/ButtonBorder";

const Banner: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const items = [
    {
      img: Read,
      title: "Revenue Growth",
      bulletPoints: [
        "Focus on increasing sales.",
        "Expanding the customer base.",
        "Boost overall revenue.",
      ],
    },
    {
      img: Handshake,
      title: "Customer Experience ",
      bulletPoints: [
        " Enhance customer satisfaction",
        "Loyalty by improving service quality",
        " Support, and user experience",
      ],
    },
    {
      img: checkBox,
      title: "Operational Efficiency",
      bulletPoints: [
        "Optimise each processes",
        "Improve productivity",
        "Streamline workflows",
      ],
    },
  ];

  return (
    <>
      <Grid
        spacing={2}
        style={{
          padding: isSmallScreen ? "20px 32px" : "50px 64px",
          backgroundColor: themestyle.colors.background,
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12} sm={6} md={6}>
          <Typography
            variant="h6"
            style={{
              color: themestyle.colors.textPrimary,
              fontFamily: "Lexend",
              fontWeight: 600,
              fontSize: isMobile ? "32px" : isTablet ? "32px" : "36px",
              marginBottom: "20px",
            }}
          >
            {data.banner["Working target"]}
          </Typography>

          <Typography
            variant="h1"
            style={{
              fontFamily: "Lexend",
              fontWeight: 400,
              fontSize: isMobile ? "18px" : isTablet ? "18px" : "24px",
              color: themestyle.colors.primary,
              marginBottom: themestyle.spacing.medium,
              lineHeight: "1.5",
              textAlign: isMobile ? "left" : "center"
            }}
          >
            {data.banner["Deliver user"]}
          </Typography>
        </Grid>

        <Grid spacing={2} container justifyContent="flex-start">
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                padding="32px 24px"
                gap="16px"
                border="2px solid #EAECF0"
                
                sx={{
                  backgroundColor: "#FFFFFF",
                  color: "#1D2939",
                  textAlign: "left",
                  transition: "border-color 0.3s",
                  height:isMobile? "290px":'242px',
                  "&:hover": {
                    border: "3px solid ",
                    borderRadius: "16px",
                    borderImage:
                      "linear-gradient(90deg, #44C597 0%, #0177BF 100%) 1",
                  },
                }}
              >
                <img
                  src={item.img}
                  alt={item.title}
                  style={{ width: "28px", height: "22px" }}
                />
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "600", fontSize: "20px",fontFamily:'Lexend' }}
                >
                  {item.title}
                </Typography>
                <List
                  dense
                  disablePadding
                  sx={{
                    "& .MuiTypography-root": {
                      fontWeight:400,
                      fontSize: "16px",
                      lineHeight: 1.0,
                      fontFamily:'Lexend'
                    },
                  }}
                >
                  {item.bulletPoints.map((point, i) => (
                    <ListItem
                      key={i}
                      disableGutters
                      sx={{
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: "20px" }}>
                        <CircleRoundedIcon
                          sx={{
                            color: "#1D2939",
                            fontSize: "6px",
                            minWidth: "10px",
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={point} />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>

    </>
  );
};

export default Banner;
