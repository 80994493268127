"use client";

import { cn } from "../../lib/utils";
import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";

export const InfiniteMovingCards = ({
  items,
  direction = "left",
  speed = "fast",
  pauseOnHover = true,
  className,
}: {
  items: {
    avatar: string;
    username: string;
    handle: string;
    comment: string;
    rating: number;
  }[];
  direction?: "left" | "right";
  speed?: "fast" | "normal" | "slow";
  pauseOnHover?: boolean;
  className?: string;
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const scrollerRef = React.useRef<HTMLUListElement>(null);
  const getImagePath = (imageName: string) =>
    require(`../../assets/${imageName}`);
  const [start, setStart] = useState(false);

  useEffect(() => {
    addAnimation();
  }, []);

  function addAnimation() {
    if (containerRef.current && scrollerRef.current) {
      const scrollerContent = Array.from(scrollerRef.current.children);

      scrollerContent.forEach((item) => {
        const duplicatedItem = item.cloneNode(true);
        if (scrollerRef.current) {
          scrollerRef.current.appendChild(duplicatedItem);
        }
      });

      getDirection();
      getSpeed();
      setStart(true);
    }
  }

  const getDirection = () => {
    if (containerRef.current) {
      if (direction === "left") {
        containerRef.current.style.setProperty(
          "--animation-direction",
          "forwards"
        );
      } else {
        containerRef.current.style.setProperty(
          "--animation-direction",
          "reverse"
        );
      }
    }
  };

  const getSpeed = () => {
    if (containerRef.current) {
      if (speed === "fast") {
        containerRef.current.style.setProperty("--animation-duration", "20s");
      } else if (speed === "normal") {
        containerRef.current.style.setProperty("--animation-duration", "40s");
      } else {
        containerRef.current.style.setProperty("--animation-duration", "80s");
      }
    }
  };

  return (
    <div
      ref={containerRef}
      className={cn(
        "scroller relative z-20 overflow-hidden [mask-image:linear-gradient(to_right,transparent,white_20%,white_80%,transparent)]",
        className
      )}
    >
      <ul
        ref={scrollerRef}
        className={cn(
          "flex min-w-full shrink-0 gap-6 py-4 w-max flex-nowrap",
          start && "animate-scroll",
          pauseOnHover && "hover:[animation-play-state:paused]"
        )}
        style={{
          display: "flex",
          minWidth: "100%",
          marginBottom: "50px",
        }}
      >
        {items.map((item, idx) => (
          <li
            className="w-[350px] max-w-full relative rounded-lg border border-gray-300 shadow-lg bg-white p-6 flex-shrink-0 transition-transform transform hover:scale-105"
            key={item.avatar}
          >
            <blockquote className="flex flex-col items-start">
              <div className="relative z-20 flex items-center gap-4 mb-4">
                <img
                  src={getImagePath(item.avatar)}
                  alt={item.username}
                  className="w-12 h-12 rounded-full border border-gray-200"
                />
                <div>
                  <span className="text-xl font-semibold text-black-400">
                    {item.username}
                  </span>
                  <br />
                  <span className="text-md text-gray-400">{item.handle}</span>
                </div>
              </div>
            </blockquote>
            <Grid sx={{ justifyContent: "flex-end" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                <Box sx={{ display: "flex", marginRight: "30px" }}>
                  {Array.from({ length: 5 }).map((_, starIndex) => (
                    <span
                      key={starIndex}
                      style={{
                        color: starIndex < item.rating ? "#FFD700" : "#E5E7EB",
                        fontSize: "18px",
                      }}
                    >
                      ★
                    </span>
                  ))}
                </Box>

                <div
                  className="relative z-20 flex items-center gap-2 mb-4"
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    padding: "2px 8px",
                    borderRadius: "4px",
                    textAlign: "left",
                  }}
                >
                  <span
                    className="text-xl"
                    style={{ fontSize: "16px", fontWeight: 400 }}
                  >
                    {item.rating} ★
                  </span>
                </div>
              </Box>
            </Grid>
            <p
              className="relative z-20 mt-2"
              style={{
                fontFamily: "Lexend",
                fontSize: "14px",
                fontWeight: 400,
                textAlign: "left", 
                margin: 0, 
                lineHeight: "1.5",
              }}
            >
              "{item.comment}"
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};
