import React, { useState } from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import themestyle from "../theme";
import professional from "../assets/professional.png";
import data from "../data/data.json";
import {TextGenerateEffect} from "./UI/TextGenerateEffect"
import { TextRevealCard } from "./UI/TextRevealCard";

const BuildBanner: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));



  return (
    <>
      <Grid
        container
        spacing={0}
        style={{
          background: "linear-gradient(90deg, #44C597 0%, #0177BF 100%)",
          padding: isSmallScreen ? "24px" : "0px",
          height: isMobile ? "auto" : isTablet ? "auto" : "539px",
        }}
      >
        <Grid
          item
          xs={12}
          sm={7}
          md={7}
          container
          direction="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <Typography
            variant="h4"
            style={{
              fontFamily: "Poppins",
              fontWeight: 700,
              fontSize: isMobile ? "32px" : isTablet ? "32px" : "36px",
              color: themestyle.colors.primary,
              marginLeft: isSmallScreen ? "0px" : "50px",
              textAlign: isMobile ? "center" : isTablet ? "center" : "left",
              padding: "10px",
            }}
          >
            <TextGenerateEffect words={`${data.aboutUs.howTo} ${data.aboutUs.websitein}`}
            fontSize={ isMobile ? "32px" : isTablet ? "32px" : "36px"}
            duration={1} />
          </Typography>
          <Typography
            style={{
              color: "rgba(255, 255, 255, 1)",
              fontSize: isMobile ? "20px" : isTablet ? "20px" : "24px",
              fontWeight: 600,
              marginLeft: isSmallScreen ? "0px" : "50px",
              padding: "10px",
              textAlign: isMobile ? "center" : isTablet ? "center" : "left",
            }}
          >
            {data.aboutUs.description}
                 
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={5}
          justifyContent="flex-end"
          alignItems="center"
        >
          <img
            src={professional}
            alt="Plan features illustration"
            style={{
              width: "100%",
              height: isMobile ? "auto" : isTablet ? "auto" : "539px",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BuildBanner;
