import React, { useState } from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  Tabs,
  Tab,
  Card,
  CardContent,
  CardMedia,
  Button,
  Box,
  Container,
  styled,
} from "@mui/material";
import themestyle from "../theme";
import PhoenixCard from "../components/PheonixCard";
import mymedi from "../assets/mostroid.png";
import brave from "../assets/brave.png";
import techno from "../assets/technoit.png";
import pathsoft from "../assets/pathsoft.png";
import { PheonixProps } from "../interfaces/HomeProps";
import data from "../data/data.json";
const images: any = {
  hazel: brave,
  pure: mymedi,
  swift: pathsoft,
  androidkit: mymedi,
  wordflow: brave,
  drupal: techno,
  mymedi: mymedi,
  shopmax: techno,
};

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  flex: 1,
  borderRadius: "5px",
  padding: "8px 16px",
  color: themestyle.colors.texttemplate,
  fontWeight: 600,
  fontFamily:'Lexend',
  [theme.breakpoints.down('sm')]: { 
    fontSize: '16px',
  },
  "&.Mui-selected": {
    color: "#fff",
    background: `linear-gradient(90deg, ${themestyle.colors.bgprimary} 0%, ${themestyle.colors.bgsecondary} 100%)`,
    height: "50px",
  },
  "&:hover": {
    background: `linear-gradient(90deg, ${themestyle.colors.bgprimary} 0%, ${themestyle.colors.bgsecondary} 100%)`,
  },
}));

const Template: React.FC<PheonixProps> = ({ sections,onEnquiryClick  }) => {
  const theme = useTheme();
  const firstTableData = data.features.planFeatures.slice(0, 11);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLaptop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };
  const getFilteredServices = () => {
    const category = data.templates.categories[selectedTab];
    return data.templates.services.filter((service) =>
      category === "All Categories" ? true : service.category === category
    );
  };

  return (
    <>
      <Grid
        container
        ref={sections.templateRef}
        sx={{
          textAlign: "center",
          justifyContent: "center",
          marginTop: themestyle.spacing.medium,
          padding: isMobile ? "16px" : "2px",
          width: "100%",
          height: "20%",
          paddingBottom:'50px'
        }}
      >
        <Grid
          item
          xs={12}
          style={{ padding: isMobile ? "23px 10px" : "23px 104px" }}
        >
          <Typography
            variant="h4"
            style={{
              fontFamily: "Lexend",
              fontWeight: 600,
              fontSize: isSmallScreen
                ? themestyle.fontSizes.large
                : themestyle.fontSizes.slarge,
              color: themestyle.colors.black,
              marginBottom: themestyle.spacing.medium,
              textAlign: "center",
            }}
          >
            {data.templates.Title}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              width: "100%",
              padding: "16px 24px",
              gap: "64px",
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: "5px",
                border: "1px solid transparent",
                background:
                  "linear-gradient(90deg, #44C597 0%, #0177BF 100%) border-box",
                WebkitMask:
                  "linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)",
                WebkitMaskComposite: "destination-out",
                maskComposite: "exclude",
              },
            }}
          >
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="service categories"
              sx={{
                width: "100%",
                borderRadius: "5px",
                "& .MuiTabs-flexContainer": {
                  display: "flex",
                  gap: theme.spacing(1),
                  flexWrap: "wrap",
                  width: "100%",
                  margin: 0,
                  justifyContent: "flex-start",
                  "& .MuiTab-root": {
                    minWidth: {
                      xs: "calc(50% - 4px)",
                      sm: "calc(50% - 4px)", 
                      md: "calc(18% - 4px)", 
                      lg: "auto"
                    },
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "16px"
                    },
                    padding: {
                      xs: "6px 12px",
                      sm: "8px 14px",
                      md: "12px 16px"
                    },
                    "@media (min-width: 768px) and (max-width: 899px)": {
                      minWidth: "calc(18% - 4px)", 
                    }
                  }
                },
                "& .MuiTabs-indicator": {
                  display: "none",
                },
              }}
            >
              {data.templates.categories.map((category, index) => (
                <StyledTab
                  key={category}
                  label={category}
                  id={`tab-${index}`}
                  aria-controls={`tabpanel-${index}`}
                />
              ))}
            </Tabs>
          </Box>
          <Box
            role="tabpanel"
            id={`tabpanel-${selectedTab}`}
            aria-labelledby={`tab-${selectedTab}`}
            sx={{
              padding: 0,
              margin: 0,
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <Box
              sx={{
                display: "grid",
                width: "100%",
                gridTemplateColumns: {
                  xs: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                gap: 3,
                margin: 0,
                padding: 0,
                boxSizing: "border-box",
                paddingTop: "20px",
              }}
            >
              {getFilteredServices().map((service) => (
                <PhoenixCard
                  key={service.id}
                  templatetitle={service.title}
                  image={images[service.imageUrl]}
                  showButton={true}
                  onButtonClick={onEnquiryClick}
                  buttonText="Enquiry"
                  type="template"
                  style={{
                    height: "299px",
                    width: "100%",
                  }}
                  sx={{
                    height: "100%",
                    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.08)',
                    "& img": {
                      width: "100%",
                      height: "167px !important",
                      objectFit: "cover",
                      marginBottom: 2,
                    },
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                />
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Template;
