import React, { useState } from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import data from "../data/data.json";
import { PheonixProps } from "../interfaces/HomeProps";
import themestyle from "../theme";

const Features: React.FC<PheonixProps> = ({ sections }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const items = [
    {
      title: "Basic Website",
      amount: "399€ 599€",
      bulletPoints: [
        "5 static web pages",
        "1-year free hosting",
        "5 accounts",
        "2GB disk space",
        "3 databases",
        "free customer support",
      ],
    },
    {
      title: "Store Essential",
      amount: "599€ 799€",
      bulletPoints: [
        "5 static pages",
        "dynamic pages (Products admin payment)",
        "flexible payment gateway",
        "1 social media integration",
        "1-year free hosting",
        "5 accounts",
        "2GB disk space",
        "3 databases",
        "free customer support",
      ],
    },
    {
      title: "Absolute Legend",
      amount: "999€ 1100€",
      bulletPoints: [
        "5 static pages",
        "3 dynamic pages (Products admin payment)",
        "chat functions ",
        "3rd-party app integration",
        "flexible payment gateway",
        "3 social media integrations",
        "1-year free hosting",
        "5 accounts",
        "2GB disk space",
        "3 databases",
        "free customer support",
      ],
    },
  ];

  return (
    <Grid
      container
      ref={sections.featureRef}
      sx={{
        textAlign: "center",
        justifyContent: "center",
        marginTop: "24px",
        padding: isMobile ? "0px" : "32px",
        color: "white",
        marginLeft: isMobile ? "0px" : "0px",
      }}
    >
      <Grid item xs={12}>
        <Typography
          variant="h3"
          sx={{
            fontSize: isMobile ? "32px" : "36px",
            fontWeight: 600,
            fontFamily: "Lexend",
            color: "#101828",
            marginBottom: isMobile ? "20px" : "40px",
          }}
        >
          {data.features.FEATURES}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          sx={{
            fontSize: isMobile ? "18px" : isTablet ? "18px" : "24px",
            fontWeight: 400,
            textAlign: "center",
            lineHeight: isMobile ? "34px" : "32px",
            fontFamily: "Lexend",
            color: "#171A1F",
            paddingBottom: "30px",
          }}
        >
          {data.features.FEATURES_TEXT}
        </Typography>
      </Grid>
      <Grid
        container
        spacing={3}
        sx={{
          justifyContent: "center",
        }}
      >
        {items.map((item, index) => (
          <FlipBox item={item} key={index} />
        ))}
      </Grid>
    </Grid>
  );
};

interface FlipBoxProps {
  item: {
    title: string;
    amount: string;
    bulletPoints: string[];
  };
}

const FlipBox: React.FC<FlipBoxProps> = ({ item }) => {
  const [flipped, setFlipped] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  return (
    <Box
      sx={{
        perspective: "1000px",
        width:isMobile?"290px": "360px",
        height: isMobile ? "590px" : "520px",
        margin:isMobile? "16px 0px 16px 23px" : "16px",
        "&:hover": { cursor: "pointer" },
      }}
      onClick={() => setFlipped(!flipped)}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          transformStyle: "preserve-3d",
          transition: "transform 0.8s",
          transform: flipped ? "rotateY(180deg)" : "rotateY(0deg)",
          border: "3px solid transparent",
          backgroundImage:
            "linear-gradient(#FFFFFF, #FFFFFF), linear-gradient(90deg, #44C597 0%, #0177BF 100%)",
          backgroundOrigin: "border-box",
          backgroundClip: "content-box, border-box",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backfaceVisibility: "hidden",
            backgroundColor: "#FFFFFF",
            border: "1px solid transparent",
            padding: "24px",
            boxShadow: "10px 10px 20px 0px #146CC526",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                color: "#1D2939",
                marginBottom: "6px",
              }}
            >
              {item.title}
            </Typography>
            <List
              dense
              disablePadding
              sx={{
                "& .MuiTypography-root": {
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontFamily: "Lexend",
                },
              }}
            >
              {item.bulletPoints.map((point, i) => (
                <ListItem
                  key={i}
                  disableGutters
                  sx={{
                    alignItems: "flex-start",
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "20px" }}>
                    <CircleRoundedIcon
                      sx={{
                        color: "#1D2939",
                        fontSize: "8px",
                        marginTop: "4px",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      point.includes("(") ? point.split("(").join("\n(") : point
                    }
                    sx={{
                      fontSize: "14px",
                      whiteSpace: "pre-wrap",
                      color: themestyle.colors.black,
                      marginTop: "-1px",
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "500",
              color: themestyle.colors.black,
              marginTop: "auto",
              textAlign: "center",
              fontFamily: "Lexend",
            }}
          >
            Tap to view price 👆
          </Typography>
        </Box>

        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backfaceVisibility: "hidden",
            backgroundColor: "#F5F5F5",
            border: "1px solid #EAECF0",
            padding: "24px",
            transform: "rotateY(180deg)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flex: "1",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "36px",
                color: "#000000",
                fontWeight: "bold",
                background: "linear-gradient(90deg, #44C597 0%, #0177BF 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {item.amount.split(" ")[0]}
            </Typography>
            <Typography
              sx={{
                fontSize: "28px",
                color: "#626262",
                textDecoration: "line-through",
              }}
            >
              {item.amount.split(" ")[1]}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "500",
              color: themestyle.colors.black,
              textAlign: "center",
              fontFamily: "Lexend",
            }}
          >
            Tap to view details 👆
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Features;
