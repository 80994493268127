"use client";
import React, { useState } from "react";
import { cn } from "../../lib/utils";
import themestyle from "../../theme";
import {
  Card as UICard,
  CardContent,
  Typography,
  Box,
  Button,
  Modal,
  IconButton,
  useTheme,
  useMediaQuery,
  Grid,
} from "@mui/material";

const Card = React.memo(
  ({
    card,
    index,
    hovered,
    setHovered,
    style,
  }: {
    card: {
      title: string;
      image: string;
      description?: string;
    };
    index: number;
    hovered: number | null;
    setHovered: React.Dispatch<React.SetStateAction<number | null>>;
    style?: React.CSSProperties;
  }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1025px)');
    const getImagePath = (image: string) => require(`../../assets/${image}`);
    const cardStyle: React.CSSProperties = {
      borderRadius: "16px",
      textAlign: "left",
      width: style?.width || (isSmallScreen ? "90%" : "422px"),
      height: style?.height || (isSmallScreen ? "291px" : isTablet ? "281px" : "224px"),
      position: "relative",
      margin: isSmallScreen ? "0 auto" : "",
      ...style,
    };

    return (
      <UICard
        onMouseEnter={() => setHovered(index)}
        onMouseLeave={() => setHovered(null)}
        className={cn(           
          "rounded-lg relative overflow-hidden transition-all duration-300 ease-out hover:shadow-[0_2px_20px_rgba(0,0,0,0.5)]",
          "w-[422px] h-[224px]",           
          isSmallScreen && "w-[90%] h-[271px] mx-auto",           
          hovered !== null && hovered !== index && "blur-sm scale-[0.98]",           
          "bg-gray-100 dark:bg-neutral-900"                  
        )}
        style={{
          ...cardStyle,
        }}
        sx={{
            backgroundColor: 'white',

           }}
      >
        <CardContent className="p-6">
          <Box
            component="img"
            src={getImagePath(card.image)}
            alt={card.title}
            width="50"
            height="50"
            mt={1}
          />

          <Typography
            variant="h5"
            sx={{
              color: "#252B42",
              fontWeight: 700,
              fontFamily: "Lexend",
              fontSize:'24px',
              lineHeight:'32px',
              textAlign: isSmallScreen ? 'left' :"center"
            }}
          >
            {card.title}
          </Typography>

          {card.description && (
            <Typography
              variant="body2"
              sx={{
                fontFamily: "Lexend",
                padding:'2px',
                fontSize:'14px',
                lineHeight:'20px',
                fontWeight:400,
                color: "#252B42",
              }}
            >
              {card.description}
            </Typography>
          )}
        </CardContent>
      </UICard>
    );
  }
);

Card.displayName = "Card";

export function FocusCards({
  cards,
}: {
  cards: Array<{
    title: string;
    image: string;
    description?: string;
  }>;
}) {
  const [hovered, setHovered] = useState<number | null>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container spacing={3}>
      {cards.map((card, index) => (
        <Grid
          item
          key={index}
          xs={12}
          sm={6}
          md={4}
          className="flex justify-center"
          flex="wrap"
        >
          <Card
            key={card.title}
            card={card}
            index={index}
            hovered={hovered}
            setHovered={setHovered}
            style={{
              width: isSmallScreen ? "90%" : "422px",
             
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default FocusCards;
