"use client";
import { useEffect } from "react";
import { motion, stagger, useAnimate } from "framer-motion";
import { cn } from "../../lib/utils";

export const TextGenerateEffect = ({
  words,
  className,
  filter = true,
  fontSize,
  duration = 0.5,
  repeat = Infinity, 
  repeatDelay = 2, 
}: {
  words: string;
  className?: string;
  filter?: boolean;
  duration?: number;
  fontSize?:string;
  repeat?: number; 
  repeatDelay?: number; 
}) => {
  const [scope, animate] = useAnimate();
  const wordsArray = words.split(" ");

  useEffect(() => {
    animate(
      "span",
      {
        opacity: 1,
        filter: filter ? "blur(0px)" : "none",
        fontSize: fontSize, 
      },
      {
        duration: duration ? duration : 1,
        delay: stagger(0.2),
        repeat: repeat, 
        repeatDelay: repeatDelay, 
      }
    );
  }, [scope.current,fontSize]);

  const renderWords = () => {
    return (
      <motion.div ref={scope}>
        {wordsArray.map((word, idx) => {
          return (
            <motion.span
            key={word + idx}
            className="dark:text-white text-black opacity-0"
            style={{
              filter: filter ? "blur(10px)" : "none",
              fontSize,
            }}
          >
              {word}{" "}
            </motion.span>
          );
        })}
      </motion.div>
    );
  };

  return (
    <div className={cn("font-bold", className)}>
      <div className="mt-4">
        <div className="dark:text-white text-black text-2xl leading-snug tracking-wide">
          {renderWords()}
        </div>
      </div>
    </div>
  );
};
