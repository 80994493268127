import React, { useState, useEffect, useCallback, MouseEvent } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  ListItemText,
  useMediaQuery,
  useTheme,
  Box,
  CssBaseline,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import pheonixlogo from "../assets/blacklogo.png";
import { NavbarProps1 } from "../interfaces/PheonixNavbarProps";

const PheonixNavbar: React.FC<NavbarProps1> = ({ sections }) => {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState<number | null>(null);
  const [indicatorStyle, setIndicatorStyle] = useState({ left: 0, width: 0 });
  const [shouldShowIndicator, setShouldShowIndicator] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const tabs = [
    { label: "Web templates", ref: sections.templateRef },
    { label: "Plan & Pricing", ref: sections.featureRef },
    {
      label: "About us",
      ref: sections.aboutRef,
      link: "https://pheonixsolutions.com/#aboutus",
    },
    { label: "Feedback", ref: sections.testimonialRef },
    { label: "Contact us", ref: sections.contactRef },
  ];

  const updateIndicatorPosition = useCallback((index: number) => {
    const buttons =
      document.querySelectorAll<HTMLButtonElement>(".navbar-tab-button");
    const buttonElement = buttons[index];

    if (buttonElement) {
      const { offsetLeft, offsetWidth } = buttonElement;
      setIndicatorStyle({ left: offsetLeft, width: offsetWidth });
    }
  }, []);

  const handleTabClick = useCallback(
    (index: number, ref: React.RefObject<HTMLDivElement>, link?: string) => {
      setActiveTab(index);
      setShouldShowIndicator(true);
      updateIndicatorPosition(index);

      if (link) {
        window.open(link, "_blank");
        return;
      }

      if (ref.current) {
        const headerOffset = 96;
        const elementPosition =
          ref.current.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    },
    [updateIndicatorPosition]
  );

  const isSectionVisible = useCallback((element: HTMLElement) => {
    const rect = element.getBoundingClientRect();
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const headerOffset = 96;

    const visibleHeight =
      Math.min(rect.bottom, windowHeight) -
      Math.max(rect.top + headerOffset, 0);
    return visibleHeight > rect.height * 0.5;
  }, []);

  const findVisibleSection = useCallback(() => {
    let visibleSectionIndex = -1;

    tabs.forEach((tab, index) => {
      if (tab.ref?.current && isSectionVisible(tab.ref.current)) {
        visibleSectionIndex = index;
      }
    });

    return visibleSectionIndex;
  }, [tabs, isSectionVisible]);

  const updateActiveTabOnScroll = useCallback(() => {
    const visibleSectionIndex = findVisibleSection();

    if (visibleSectionIndex !== -1) {
      setActiveTab(visibleSectionIndex);
      setShouldShowIndicator(true);
      updateIndicatorPosition(visibleSectionIndex);
    } else {
      setShouldShowIndicator(false);
    }
  }, [findVisibleSection, updateIndicatorPosition]);

  useEffect(() => {
    let ticking = false;

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          updateActiveTabOnScroll();
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    updateActiveTabOnScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, [updateActiveTabOnScroll]);

  useEffect(() => {
    let resizeTimeout: NodeJS.Timeout;

    const handleResize = () => {
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }

      resizeTimeout = setTimeout(() => {
        if (activeTab !== null && shouldShowIndicator) {
          updateIndicatorPosition(activeTab);
        }
        updateActiveTabOnScroll();
      }, 100);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }
      window.removeEventListener("resize", handleResize);
    };
  }, [
    activeTab,
    shouldShowIndicator,
    updateIndicatorPosition,
    updateActiveTabOnScroll,
  ]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          background: "#ffffff",
          justifyContent: "space-around",
          height: isMobile ? "60px" : "96px",
        }}
      >
        <Toolbar>
          <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            <img
              src={pheonixlogo}
              alt="pheonixlogo"
              style={{
                height: isMobile ? "43px" : "83px",
                width: isMobile ? "68px" : "128px",
              }}
            />
            {!isMobile && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexGrow: 1,
                  position: "relative",
                }}
              >
                {tabs.map((tab, index) => (
                  <Button
                    key={index}
                    className="navbar-tab-button"
                    onClick={() => handleTabClick(index, tab.ref, tab.link)}
                    sx={{
                      backgroundColor: "transparent",
                      marginRight: "16px",
                      textTransform: "none",
                      fontFamily: "Lexend",
                      fontSize: "16px",
                      color: activeTab === index && shouldShowIndicator ? "#208BEE" : "#263238",
                      fontWeight: 600,
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    {tab.label}
                  </Button>
                ))}
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    height: "3px",
                    backgroundColor: "#208BEE",
                    borderRadius: "1px",
                    transition: "all 0.3s ease",
                    visibility: shouldShowIndicator ? "visible" : "hidden",
                  }}
                  style={{
                    left: `${indicatorStyle.left}px`,
                    width: `${indicatorStyle.width}px`,
                  }}
                />
              </Box>
            )}
          </div>
          {isMobile && (
            <>
              <IconButton edge="end" aria-label="menu" onClick={handleMenuOpen}>
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                sx={{ width: "285px", marginTop: "10px", padding: "30px" }}
              >
                {tabs.map((tab, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleTabClick(index, tab.ref, tab.link)}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="133px"
                      marginLeft="30px"
                    >
                      <ListItemText
                        primary={tab.label}
                        primaryTypographyProps={{
                          fontWeight: 600,
                        }}
                      />
                    </Box>
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default PheonixNavbar;
